import React, { Component } from 'react';

class PDFViewer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div style={{ width: '100%', maxWidth: '100%', height: '100vh' }}>
                    <iframe src="https://essstr.blob.core.windows.net/tabloid/Iconic%20Menu%20Book.pdf"
                        style={{ width: '100%', height: '100%', border: '0px', display: 'block', backgroundColor: '#4a4a4a' }}
                        webkitallowfullscreen={true} mozallowfullscreen={true} allowFullScreen={true}
                    >
                    </iframe>
                </div>
            </div>
        );
    }
}

export default PDFViewer