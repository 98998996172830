const initialState = {
    customerID: 0,
    firstName: "",
    lastName: "",
    customerName: "",
    email: "",
    mobileNumber: "",
    isNexus: false,
    mobileNumberVerified: false,
    addressLine1: "",
    addressLine2: "",
    city: "",
    title: "",
    userSessionID: '',
    migrationVerified: true,
    isTemporaryCartExist: false,
    isGuest: false,
    birthday: "",
    contactNumber: "",
    isFirstLogin: false,
    isDetailsCompleted: true,
    isHasNotification: false,
    refundOptionID: 0,
    mobileCountryCode: "",
    contactCountryCode: "",
    isAddressCompleted: true,
    isPremiumCustomer: false,
    isFirstLoginAfterPremium: false,
    premiumOutlet: ""
};

export const userDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_USER_DETAIL':
            return {
                customerID: action.payload.customerID,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                customerName: action.payload.customerName,
                email: action.payload.email,
                mobileNumber: action.payload.mobileNumber,
                isNexus: action.payload.isNexus,
                mobileNumberVerified: action.payload.mobileNumberVerified,
                addressLine1: action.payload.addressLine1,
                addressLine2: action.payload.addressLine2,
                city: action.payload.city,
                title: action.payload.title,
                userSessionID: action.payload.userSessionID,
                migrationVerified: action.payload.migrationVerified,
                isTemporaryCartExist: action.payload.isTemporaryCartExist,
                isGuest: action.payload.isGuest,
                birthday: action.payload.birthday,
                contactNumber: action.payload.contactNumber,
                isFirstLogin: action.payload.isFirstLogin,
                isDetailsCompleted: action.payload.isDetailsCompleted,
                isHasNotification: action.payload.isHasNotification,
                mobileCountryCode: action.payload.mobileCountryCode,
                contactCountryCode: action.payload.contactCountryCode,
                refundOptionID: action.payload.refundOptionID,
                isAddressCompleted: action.payload.isAddressCompleted,
                isPremiumCustomer: action.payload.isPremiumCustomer,
                isFirstLoginAfterPremium: action.payload.isFirstLoginAfterPremium,
                premiumOutlet: action.payload.premiumOutlet
            };
        case 'UPDATE_IS_TEMPORARY_CART':
            return {
                customerID: state.customerID,
                firstName: state.firstName,
                lastName: state.lastName,
                customerName: state.customerName,
                email: state.email,
                mobileNumber: state.mobileNumber,
                isNexus: state.isNexus,
                mobileNumberVerified: state.mobileNumberVerified,
                addressLine1: state.addressLine1,
                addressLine2: state.addressLine2,
                city: state.city,
                title: state.title,
                userSessionID: state.userSessionID,
                migrationVerified: state.migrationVerified,
                isGuest: state.isGuest,
                birthday: state.birthday,
                contactNumber: state.contactNumber,
                isFirstLogin: state.isFirstLogin,
                isDetailsCompleted: state.isDetailsCompleted,
                isHasNotification: state.isHasNotification,
                refundOptionID: state.refundOptionID,
                mobileCountryCode: state.mobileCountryCode,
                contactCountryCode: state.contactCountryCode,
                isAddressCompleted: state.isAddressCompleted,
                isPremiumCustomer: state.isPremiumCustomer,
                isFirstLoginAfterPremium: state.isFirstLoginAfterPremium,
                premiumOutlet: state.premiumOutlet,

                isTemporaryCartExist: action.payload,
            };
        case 'UPDATE_IS_FIRST_LOGIN':
            return {
                customerID: state.customerID,
                firstName: state.firstName,
                lastName: state.lastName,
                customerName: state.customerName,
                email: state.email,
                mobileNumber: state.mobileNumber,
                isNexus: state.isNexus,
                mobileNumberVerified: state.mobileNumberVerified,
                addressLine1: state.addressLine1,
                addressLine2: state.addressLine2,
                city: state.city,
                title: state.title,
                userSessionID: state.userSessionID,
                migrationVerified: state.migrationVerified,
                isGuest: state.isGuest,
                birthday: state.birthday,
                contactNumber: state.contactNumber,
                isDetailsCompleted: state.isDetailsCompleted,
                isTemporaryCartExist: state.isTemporaryCartExist,
                isHasNotification: state.isHasNotification,
                refundOptionID: state.refundOptionID,
                mobileCountryCode: state.mobileCountryCode,
                contactCountryCode: state.contactCountryCode,
                refundOptionID: state.refundOptionID,
                isAddressCompleted: state.isAddressCompleted,
                isPremiumCustomer: state.isPremiumCustomer,
                isFirstLoginAfterPremium: state.isFirstLoginAfterPremium,
                premiumOutlet: state.premiumOutlet,

                isFirstLogin: action.payload,
            };
        case 'UPDATE_IS_NEXUS_FLAG':
            return {
                customerID: state.customerID,
                firstName: state.firstName,
                lastName: state.lastName,
                customerName: state.customerName,
                email: state.email,
                mobileNumber: state.mobileNumber,
                mobileNumberVerified: state.mobileNumberVerified,
                addressLine1: state.addressLine1,
                addressLine2: state.addressLine2,
                city: state.city,
                title: state.title,
                userSessionID: state.userSessionID,
                migrationVerified: state.migrationVerified,
                isGuest: state.isGuest,
                birthday: state.birthday,
                contactNumber: state.contactNumber,
                isDetailsCompleted: state.isDetailsCompleted,
                isHasNotification: state.isHasNotification,
                isTemporaryCartExist: state.isTemporaryCartExist,
                isFirstLogin: state.isFirstLogin,
                mobileCountryCode: state.mobileCountryCode,
                contactCountryCode: state.contactCountryCode,
                refundOptionID: state.refundOptionID,
                isAddressCompleted: state.isAddressCompleted,
                isPremiumCustomer: state.isPremiumCustomer,
                isFirstLoginAfterPremium: state.isFirstLoginAfterPremium,
                premiumOutlet: state.premiumOutlet,

                isNexus: action.payload,
            };
        case 'UPDATE_ADDRESS':
            return {
                customerID: state.customerID,
                firstName: state.firstName,
                lastName: state.lastName,
                customerName: state.customerName,
                email: state.email,
                mobileNumber: state.mobileNumber,
                isNexus: state.isNexus,
                mobileNumberVerified: state.mobileNumberVerified,
                title: state.title,
                userSessionID: state.userSessionID,
                migrationVerified: state.migrationVerified,
                isGuest: state.isGuest,
                birthday: state.birthday,
                contactNumber: state.contactNumber,
                isDetailsCompleted: state.isDetailsCompleted,
                isTemporaryCartExist: state.isTemporaryCartExist,
                isFirstLogin: state.isFirstLogin,
                isHasNotification: state.isHasNotification,
                refundOptionID: state.refundOptionID,
                mobileCountryCode: state.mobileCountryCode,
                contactCountryCode: state.contactCountryCode,
                refundOptionID: state.refundOptionID,
                isAddressCompleted: state.isAddressCompleted,
                isPremiumCustomer: state.isPremiumCustomer,
                isFirstLoginAfterPremium: state.isFirstLoginAfterPremium,
                premiumOutlet: state.premiumOutlet,

                addressLine1: action.payload.addressLine1,
                addressLine2: action.payload.addressLine2,
                city: action.payload.city,
            };
        case 'UPDATE_HAS_NOTIFICATION_FLAG':
            return {
                customerID: state.customerID,
                firstName: state.firstName,
                lastName: state.lastName,
                customerName: state.customerName,
                email: state.email,
                mobileNumber: state.mobileNumber,
                mobileNumberVerified: state.mobileNumberVerified,
                addressLine1: state.addressLine1,
                addressLine2: state.addressLine2,
                city: state.city,
                title: state.title,
                userSessionID: state.userSessionID,
                migrationVerified: state.migrationVerified,
                isGuest: state.isGuest,
                birthday: state.birthday,
                contactNumber: state.contactNumber,
                isDetailsCompleted: state.isDetailsCompleted,
                isTemporaryCartExist: state.isTemporaryCartExist,
                isFirstLogin: state.isFirstLogin,
                isNexus: state.isNexus,
                mobileCountryCode: state.mobileCountryCode,
                contactCountryCode: state.contactCountryCode,
                refundOptionID: state.refundOptionID,
                isAddressCompleted: state.isAddressCompleted,
                isPremiumCustomer: state.isPremiumCustomer,
                isFirstLoginAfterPremium: state.isFirstLoginAfterPremium,
                premiumOutlet: state.premiumOutlet,

                isHasNotification: action.payload
            };
        case 'UPDATE_REFUND_OPTION':
            return {
                customerID: state.customerID,
                firstName: state.firstName,
                lastName: state.lastName,
                customerName: state.customerName,
                email: state.email,
                mobileNumber: state.mobileNumber,
                isNexus: state.isNexus,
                mobileNumberVerified: state.mobileNumberVerified,
                addressLine1: state.addressLine1,
                addressLine2: state.addressLine2,
                city: state.city,
                title: state.title,
                userSessionID: state.userSessionID,
                migrationVerified: state.migrationVerified,
                isTemporaryCartExist: state.isTemporaryCartExist,
                isGuest: state.isGuest,
                birthday: state.birthday,
                contactNumber: state.contactNumber,
                isFirstLogin: state.isFirstLogin,
                isDetailsCompleted: state.isDetailsCompleted,
                isHasNotification: state.isHasNotification,
                mobileCountryCode: state.mobileCountryCode,
                contactCountryCode: state.contactCountryCode,
                isAddressCompleted: state.isAddressCompleted,
                isPremiumCustomer: state.isPremiumCustomer,
                isFirstLoginAfterPremium: state.isFirstLoginAfterPremium,
                premiumOutlet: state.premiumOutlet,

                refundOptionID: action.payload
            };
        case 'UPDATE_ISADDRESSCOMPLETED':
            return {
                customerID: state.customerID,
                firstName: state.firstName,
                lastName: state.lastName,
                customerName: state.customerName,
                email: state.email,
                mobileNumber: state.mobileNumber,
                isNexus: state.isNexus,
                mobileNumberVerified: state.mobileNumberVerified,
                title: state.title,
                userSessionID: state.userSessionID,
                migrationVerified: state.migrationVerified,
                isGuest: state.isGuest,
                birthday: state.birthday,
                contactNumber: state.contactNumber,
                isDetailsCompleted: state.isDetailsCompleted,
                isTemporaryCartExist: state.isTemporaryCartExist,
                isFirstLogin: state.isFirstLogin,
                isHasNotification: state.isHasNotification,
                refundOptionID: state.refundOptionID,
                mobileCountryCode: state.mobileCountryCode,
                contactCountryCode: state.contactCountryCode,
                refundOptionID: state.refundOptionID,
                addressLine1: state.addressLine1,
                addressLine2: state.addressLine2,
                city: state.city,
                isPremiumCustomer: state.isPremiumCustomer,
                isFirstLoginAfterPremium: state.isFirstLoginAfterPremium,
                premiumOutlet: state.premiumOutlet,

                isAddressCompleted: action.payload,
            };
        case 'UPDATE_IS_FIRST_LOGIN_AFTER_PREMIUM':
            return {
                customerID: state.customerID,
                firstName: state.firstName,
                lastName: state.lastName,
                customerName: state.customerName,
                email: state.email,
                mobileNumber: state.mobileNumber,
                isNexus: state.isNexus,
                mobileNumberVerified: state.mobileNumberVerified,
                title: state.title,
                userSessionID: state.userSessionID,
                migrationVerified: state.migrationVerified,
                isGuest: state.isGuest,
                birthday: state.birthday,
                contactNumber: state.contactNumber,
                isDetailsCompleted: state.isDetailsCompleted,
                isTemporaryCartExist: state.isTemporaryCartExist,
                isFirstLogin: state.isFirstLogin,
                isHasNotification: state.isHasNotification,
                refundOptionID: state.refundOptionID,
                mobileCountryCode: state.mobileCountryCode,
                contactCountryCode: state.contactCountryCode,
                refundOptionID: state.refundOptionID,
                addressLine1: state.addressLine1,
                addressLine2: state.addressLine2,
                city: state.city,
                isPremiumCustomer: state.isPremiumCustomer,
                premiumOutlet: state.premiumOutlet,
                isAddressCompleted: action.isAddressCompleted,

                isFirstLoginAfterPremium: action.payload,
            };
        case 'RESET_USER_DETAIL':
            return initialState;
        default:
            return state;
    }
};